<template>
  <div>
    <el-result
        icon="success"
        title="提交成功"
        sub-title=""
    >
    </el-result>
  </div>
</template>

<script>
export default {
  name: "addsuccess"
}
</script>

<style scoped>

</style>